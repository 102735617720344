import {
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import Dashboard from "./Dashboard"
import Apps from "./Apps/Apps"
import AppDetail from "./Apps/AppDetail"
import Roles from "./Roles/Roles"
import RoleDetail from "./Roles/RoleDetail"
import Users from "./Users/Users"
import UserDetail from "./Users/UserDetail"
import ProtectedRoute from "../ProtectedRoute"
export default function Index() {
    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Dashboard />} />                
                    <Route path="/apps" element={<Apps />} />
                    <Route path="/apps/create" element={<AppDetail />} />
                    <Route path="/apps/:id" element={<AppDetail />} />
                    <Route path="/roles" element={<Roles />} />
                    <Route path="/roles/create" element={<RoleDetail />} />  
                    <Route path="/roles/:id" element={<RoleDetail />} />  
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/create" element={<UserDetail />} />
                    <Route path="/users/:id" element={<UserDetail />} />                                                          
                </Route>
                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}