import { useMemo } from "react"
import { useTable } from "react-table"
export default function UsersTable(props) {
  const { items } = props
  items.map(function (item) {
    item.statusBadge = <Badge status={item.status} />
    item.details = <a className="text-blue-600 font-bold hover:underline" href={`/users/${item.id}`}>Detalles</a>
    return item
  })
  const data = useMemo(
    () => items
  )
  const columns = useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'name', // accessor is the "key" in the data
      },
      {
        Header: 'Apellido',
        accessor: 'lastName',
      },
      {
        Header: 'Correo',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'statusBadge',
      },
      {
        Header: '',
        accessor: 'details',
      }
    ],
    []
  )
  function Badge({status}){
    let label = status ? 'Activo' : 'Inactivo'
    return (
      <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full ${status ? 'text-green-800 bg-green-100':'text-red-800 bg-red-100'}`}>
        {label}
      </span>
    )
  }
  const tableInstance = useTable({ columns, data })
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance
  return (
    <table
      {...getTableProps()}
      className="w-full text-sm text-left ">
      <thead
        className="text-gray-700 uppercase bg-gray-50 ">
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="py-3 px-6"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              className="bg-white border-b"
            >
              {row.cells.map(cell => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="py-4 px-6"
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}