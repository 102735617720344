import axios from "axios"
const API_URL = process.env.REACT_APP_API_URL

const login = (email, password) => {
    return axios
        .post(`${API_URL}/auth/root/login`, {
            email,
            password,
        })
        .then(response => {
            if (response.status === 200) {
                const { data } = response.data
                localStorage.setItem("miaToken", data.token)
                localStorage.setItem("userInfo", data.user)
            }
            return response
        })
        .catch(error => {
            return error.response
        })
}

const logout = () => {
    localStorage.removeItem("miaToken")
    localStorage.removeItem("userInfo")
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("userInfo"))
}

const getCurrentToken = () => {
    return localStorage.getItem("miaToken")
}

const isLoggedIn = () => {
    if (getCurrentToken()) {
        return true
    }
    return false
}

export default {
    login,
    logout,
    isLoggedIn
};