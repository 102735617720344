import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Contatiner from "../Container"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import RoleService from "../../../services/role.service"
import AppsService from "../../../services/apps.service"
import RoleForm from "./RoleForm"
export default function RoleDetail(props) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isNew, setIsNew] = useState(false)
    const [role, setRole] = useState({
        id: undefined,
        code: '',
        description: '',
        status: false,
        appId: 0,
        rules: ''
    })
    const [apps, setApps] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            getApps()
            if (id === undefined) {
                setIsNew(true)
            }
            else {
                getRole()
            }
        }
        return () => (isSubscribed = false)
    }, [])

    const getRole = () => {
        RoleService.getRole(id)
            .then(response => {
                if (response.status === 200) {
                    setRole(response.data.data)
                }
            })
    }

    const getApps = () => {
        AppsService.getApps()
            .then(response => {
                if (response.status === 200) {
                    setApps(response.data.data)
                }
            })
    }

    const onSubmit = (props) => {
        let roleData = {
            id: props.id,
            code: props.code,
            description: props.description,
            status: props.statusOption.value,
            appId: props.appOption.value,
            rules: props.rules
        }
        setLoading(true)
        if (isNew) {
            roleData.id = roleData.id !== "" ? roleData.id : undefined
            RoleService.createRole(roleData)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                        setRole(response.data.data)
                        navigate("/roles")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
        else {
            RoleService.updateRole(roleData)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
    }

    return (
        <Contatiner>
            <div className="flex items-center w-full justify-between">
                <h1 className="font-bold text-xl">Roles</h1>
            </div>
            <div className="pt-2">
                {
                    isNew ?
                        <p>Crear nueva rol</p>
                        :
                        <p>Detalle rol</p>
                }
            </div>
            <div className="pt-8 w-full">
                <div className="bg-white shadow-lg h-auto w-full md:w-4/6 lg:w-3/6 p-8 rounded-lg">
                    <RoleForm id={id} role={role} submit={onSubmit} loading={loading} apps={apps} />
                </div>
            </div>
        </Contatiner>
    )
}