import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getUsers = () => {
  return axios.get(`${API_URL}/users`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getUser = (id) => {
  return axios.get(`${API_URL}/users/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createUser = (data) => {
  return axios.post(`${API_URL}/users/create`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}
const updateUser = (data) => {
  return axios.post(`${API_URL}/users/update/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser
}
