import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getRoles = () => {
  return axios.get(`${API_URL}/roles`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createRole = (data) => {
  return axios.post(`${API_URL}/roles/create`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getRole = (id) => {
  return axios.get(`${API_URL}/roles/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateRole = (data) => {
  return axios.post(`${API_URL}/roles/update/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
    getRoles,
    createRole,
    getRole,
    updateRole
  }
  