import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Contatiner from "../Container"
import UserForm from "./UserForm"
import UsersService from "../../../services/user.service"
import AppsService from "../../../services/apps.service"
import RoleService from "../../../services/role.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function UserDetail(props) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isNew, setIsNew] = useState(false)
    const [user, setUser] = useState({
        id: undefined,
        name: '',
        lastName: '',
        email: '',
        password: '',
        status: true
    })
    const [apps, setApps] = useState([])
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)
    const onSubmit = (props) => {
        let userApps = props.userAppsOptions.map(function(app){
            return app.value
        })
        let userRoles = props.userRolesOptions.map(function(role){
            return role.value
        })
        let userData = {
            id: props.id,
            name: props.name,
            lastName: props.lastName,
            email: props.email,
            password: props.password,
            status: props.status,
            userApps: userApps,
            userRoles: userRoles
        }
        userData.password = userData.password.trim() === "" ? undefined : userData.password.trim()
        setLoading(true)
        if (isNew) {
            userData.id = userData.id !== "" ? userData.id : undefined
            UsersService.createUser(userData)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                        setUser(response.data.data)
                        navigate("/users")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
        else {
            UsersService.updateUser(userData)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            if (id === undefined) {
                setIsNew(true)
                getApps()
                getRoles()
            }
            else {
                UsersService.getUser(id)
                    .then(response => {
                        if (response.status === 200) {
                            const userData =
                            {
                                ...response.data.data.user,
                                userApps: response.data.data.userApps,
                                userRoles: response.data.data.userRoles,
                            }
                            setUser(userData)
                            setApps(response.data.data.apps)
                            setRoles(response.data.data.roles)
                        }
                    })

            }
        }
        return () => (isSubscribed = false)
    }, [])

    const getApps = () => {
        AppsService.getApps()
            .then(response => {
                if (response.status === 200) {
                    setApps(response.data.data)
                }
            })
    }

    const getRoles = () => {
        RoleService.getRoles()
            .then(response => {
                if (response.status === 200) {
                    setRoles(response.data.data.roles)
                }
            })
    }

    return (
        <>
            <Contatiner>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Usuario</h1>
                </div>
                <div className="pt-2">
                    {
                        isNew ?
                            <p>Crear nuevo usuario</p>
                            :
                            <p>Detalle usuario</p>
                    }
                </div>
                <div className="pt-8 w-full">
                    <div className="bg-white shadow-lg h-auto w-full p-8 rounded-lg">
                        <UserForm
                            loading={loading}
                            id={id}
                            submit={onSubmit}
                            user={user}
                            apps={apps}
                            roles={roles}
                        />
                    </div>
                </div>
            </Contatiner>
        </>

    )
}