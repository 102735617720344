import Contatiner from "../Container"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import RolseService from "../../../services/role.service"
import Table from "../Table"
export default function Roles() {
    const navigate = useNavigate()
    const [roles, setRoles] = useState([])
    const [apps, setApps] = useState([])
    const tableColumns = [
        {
            Header: 'Código',
            accessor: 'code', // accessor is the "key" in the data
        },
        {
            Header: 'Descripción',
            accessor: 'description',
        },
        {
            Header: 'APP',
            accessor: 'apptag'
        },
        {
            Header: 'Status',
            accessor: 'statusBadge',
        },
        {
            Header: '',
            accessor: 'details',
        }
    ]
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            RolseService.getRoles()
                .then(response => {
                    if (response.status === 200) {
                        formatData(response.data.data)
                    }
                })
        }
        return () => (isSubscribed = false)
    }, [])

    const formatData = (data) => {
        let { roles, apps } = data
        roles.map(function (item) {
            const app = apps.filter(app => app.id === item.appId)[0]
            item.apptag = <BadgeDefault label={app.description} />
            item.statusBadge = <Badge status={item.status} />
            item.details = <a className="text-blue-600 font-bold hover:underline" href={`/roles/${item.id}`}>Detalles</a>
            return item
        })
        setRoles(roles)
        setApps(apps)
    }

    function Badge({ status }) {
        let label = status ? 'Activo' : 'Inactivo'
        return (
            <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full ${status ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100'}`}>
                {label}
            </span>
        )
    }
    function BadgeDefault({ label }) {
        return (
            <span className={`inline-flex items-center py-1 px-2 mr-2 text-sm font-medium rounded-full text-purple-800 bg-purple-100`}>
                {label}
            </span>
        )
    }
    return (
        <>
            <Contatiner>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Roles</h1>
                    <button
                        className="py-2 px-4 bg-blue-600 text-white rounded-lg font-bold"
                        onClick={() => navigate("/roles/create")}
                    >
                        Nuevo Rol
                    </button>
                </div>
                <div className="pt-8">
                    <Table tableColumns={tableColumns} tableItems={roles} />
                </div>
            </Contatiner>
        </>
    )
}