import axios from "axios"
import authHeader from "./auth-header"
// Request interceptor for API calls
axios.interceptors.request.use(
  config => {
    config.headers = authHeader()
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
axios.interceptors.response.use((response) => {
    return response;
  },function (error) {
    if (error.response.status === 401) {
        localStorage.removeItem("userInfo")
        localStorage.removeItem("miaToken")
        window.location.href = '/login';        
        return Promise.reject(error);
    }
    return Promise.reject(error);
  });
export default axios;
/*
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});
*/