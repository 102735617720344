import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
export default function Navigation(props) {
    const logOut = () => {
        AuthService.logout();
    }
    return (
        <nav className="sticky h-[100vh] border-red-100 bg-white w-[14rem]">
            <div className="w-full h-[5rem] flex justify-center items-center">
                <p>Logo</p>
            </div>
            <ul className="p-4">
                <li>
                    <Link to="/" className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-4 w-full">
                            Dashboard
                        </span>
                    </Link>
                </li>
                <li>
                    <Link to="/apps" className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-4 w-full">
                            Apps
                        </span>
                    </Link>
                </li>
                <li>
                    <Link to="/roles" className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-4 w-full">
                            Roles
                        </span>
                    </Link>
                </li>
                <li>
                    <Link to="/users" className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-4 w-full">
                            Usuarios
                        </span>
                    </Link>
                </li>
                <li>
                    <a href="/login" onClick={logOut} className="w-full flex rounded-lg hover:bg-slate-100">
                        <span className="p-4 w-full">
                            Salir
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    )
}