import axios from "./axios_interceptor"
const API_URL = process.env.REACT_APP_API_URL

const getApps = () => {
  return axios.get(`${API_URL}/apps`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const createApp = (data) => {
  return axios.post(`${API_URL}/apps/create`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const getApp = (id) => {
  return axios.get(`${API_URL}/apps/${id}`)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

const updateApp = (data) => {
  return axios.post(`${API_URL}/apps/update/${data.id}`, data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error.response
    })
}

export default {
  getApps,
  createApp,
  getApp,
  updateApp
}
