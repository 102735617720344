import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Contatiner from "../Container"
import AppForm from "./AppForm"
import AppsService from "../../../services/apps.service"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
export default function AppDetail(props) {
    const navigate = useNavigate()
    const { id } = useParams()
    const [isNew, setIsNew] = useState(false)
    const [app, setApp] = useState({
        id: undefined,
        code: '',
        description: '',
        status: true
    })
    const [loading, setLoading] = useState(false)
    const onSubmit = (props) => {
        setLoading(true)
        if (isNew) {
            props.id = props.id !== "" ? props.id : undefined
            AppsService.createApp(props)
                .then(response => {                    
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                        setApp(response.data.data)
                        navigate("/apps")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
        else {
            AppsService.updateApp(props)
                .then(response => {
                    if (response.status === 200) {
                        toast.success("Se actualizó la información correctamente")
                    }
                    else {
                        toast.error("Ocurrió un error al actualizar la información")
                    }
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            if (id === undefined) {
                setIsNew(true)
            }
            else {
                AppsService.getApp(id)
                    .then(response => {
                        if (response.status === 200) {
                            setApp(response.data.data)
                        }
                    })

            }
        }
        return () => (isSubscribed = false)
    }, [])

    return (
        <>
            <Contatiner>
                <div className="flex items-center w-full justify-between">
                    <h1 className="font-bold text-xl">Usuario</h1>
                </div>
                <div className="pt-2">
                    {
                        isNew ?
                            <p>Crear nueva aplicación</p>
                            :
                            <p>Detalle aplicación</p>
                    }
                </div>
                <div className="pt-8 w-full">
                    <div className="bg-white shadow-lg h-auto w-full md:w-4/6 lg:w-3/6 p-8 rounded-lg">
                        <AppForm id={id} app={app} submit={onSubmit} loading={loading} />
                    </div>
                </div>
            </Contatiner>
        </>

    )
}