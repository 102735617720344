import Navigation from "./Navigation"
export default function Contatiner(props) {
    return (
        <div className="flex flex-row">
            <Navigation></Navigation>
            <div className="flex-1 basis-[0%]">
                <main>
                    <div className="p-[4rem]">
                        {props.children}
                    </div>
                </main>
            </div>
        </div>
    )
}