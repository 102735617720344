import Contatiner from "../Container"
import UsersTable from "./UsersTable"
import { useEffect, useState } from "react"
import UserService from "../../../services/user.service"
import { useNavigate } from "react-router-dom"
export default function Users() {
    const [users, setUsers] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        let isSubscribed = true
        if (isSubscribed) {
            UserService.getUsers()
                .then(response => {
                    if(response.status === 200){
                        setUsers(response.data.data)
                    }
                })
        }
        return () => (isSubscribed = false)
    }, [])
    return (
        <>
            <Contatiner>
                <div className="flex items-center w-full justify-between">
                <h1 className="font-bold text-xl">Usuarios</h1>
                    <button 
                        className="py-2 px-4 bg-blue-600 text-white rounded-lg font-bold"
                        onClick={ () => navigate("/users/create")}
                    >
                        Nuevo Usuario
                    </button>
                </div>                
                <div className="pt-8">
                    <UsersTable items={users}/>
                </div>
            </Contatiner>
        </>
    )
}